<template>
  <div :class="`m-3 p-2 wrapper  wrapper--${bu}`">
    <div class="text-center p-2">
      <h3>Landen overzicht</h3>
      <span @click="previousYear"><i class="fas fa-arrow-left"></i></span>
      <span class="text-center m-2"
        >Jaar: {{ year_number }}</span
      >
      <span @click="nextYear"><i class="fas fa-arrow-right"></i></span>
    </div>
    <Loading v-if="loading" />
    <Tabs>
      <Tab
        v-for="(continent, key) in country_data"
        :key="key"
        :title="key"
        :selected="key == 'totaal' ? true : false"
      >
        <CountryOverviewTable
          :table_style="bu"
          :country_data="continent"
          :country_totals="country_totals[key]"
          :year="year_number"
        />
      </Tab>
    </Tabs>
  </div>
</template>

<script>
import request from "@/functions/request.js";
import Loading from "@/components/Loading.vue";
import Tabs from "@/components/Tabs/Tabs.vue";
import Tab from "@/components/Tabs/Tab.vue";
import CountryOverviewTable from "@/components/CountryOverviewTable.vue";
import { subtractTime, addTime } from "@/functions/date.js"

export default {
  props: ["bu"],
  components: { Tab, Tabs, Loading, CountryOverviewTable },
  data: () => ({
    loading: true,
    year_number: null,
    full_date: null,
    country_data: null,
    country_totals: null,
  }),
  created() {
    this.full_date = this.moment();
    this.getData(this.bu);
  },
  watch: {
    bu(newbu) {
      this.getData(newbu);
    },
  },
  methods: {
    getData(bu) {
      this.year_number = this.full_date.year();
      request(`country-overview/${bu}/${this.year_number}`, "GET").then(
        ({ country_data, country_totals }) => {
          this.country_totals = country_totals;
          this.country_data = country_data;
          this.loading = false;
        }
      );
    },

    previousYear() {
      this.loading = true;
      this.country_data = null;
      this.country_totals = null;
      this.full_date = subtractTime(1, this.full_date, "years");
      this.getData(this.bu);
    },

    nextYear() {
      this.loading = true;
      this.country_data = null;
      this.country_totals = null;
      this.full_date = addTime(1, this.full_date, "years");
      this.getData(this.bu);
    },
  },
};
</script>
