<template>
  <table :class="`m2 w100 table__border--${table_style}`">
    <thead :class="`table__head--${table_style}`">
      <th></th>
      <th>Naam</th>
      <th>Aantal</th>
      <th>Omzet</th>
      <th>Commissie</th>
      <th>Winst</th>
      <th>Winst %</th>
    </thead>
    <tbody>
        <tr v-for="(item, key) in country_data" :key="key">
            <td style="max-width:10px;" class="text-center"><Flag v-if="item.land != 'GB'" :code="item.land" /> <Flag v-else :code="`GB-UKM`" /></td>
            <td>  <router-link
              :to="{
                name: 'countryoverviewdetails',
                params: { bu: table_style, countryCode: item.land, country: item.naam, year:year },
              }"
              >{{ item.naam }}</router-link
            ></td>
            <td class="table__data--right">{{ item.aantal }}</td>
            <td v-if="checkroles(['marges_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)" class="table__data--right">€{{ item.verkopen }}</td>
            <td v-if="checkroles(['marges_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)" class="table__data--right">€{{ item.commisie }}</td>
            <td v-if="checkroles(['marges_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)" class="table__data--right">€{{ item.winst }}</td>
            <td v-if="checkroles(['marges_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)" class="table__data--right">{{ item.winstperc }}%</td>
        </tr>
    </tbody>
    <tfoot>
        <tr>
            <th></th>
            <th class="table__data--right">{{ country_totals.aantal_landen }}</th>
            <th class="table__data--right">{{ country_totals.aantal }}</th>
            <th v-if="checkroles(['marges_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)" class="table__data--right">€{{ country_totals.omzet }}</th>
            <th v-if="checkroles(['marges_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)" class="table__data--right">€{{ country_totals.commissie }}</th>
            <th v-if="checkroles(['marges_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)" class="table__data--right">€{{ country_totals.winst }}</th>
            <th v-if="checkroles(['marges_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)" class="table__data--right">{{ country_totals.winstperc }}%</th>
        </tr>
    </tfoot>
  </table>
</template>

<script>
export default {
  props: {
    country_data: [Array, Object],
    country_totals: [Array, Object],
    table_style: [String, Number],
    year: [Number, String]
  },
};
</script>

